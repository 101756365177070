<template>
    <mercur-card class="full-height-layout fill mx-4 mb-3">
        <form @submit.prevent="submit">
            <h2 class="font-weight-light">
                <span v-if="isEdit && !isLoading">Edit {{ category.categoryName }}</span>
                <span v-else>Add category</span>
            </h2>
            <div class="row">
                <div class="col-6">
                    <mercur-input v-model="$v.form.categoryName.$model" required :disabled="isLoading" :class="{'form-invalid': $v.form.categoryName.$error}">
                        Category name
                        <template slot="note">
                            <span class="form-error" v-if="!$v.form.categoryName.required">Category name is required</span>
                        </template>
                    </mercur-input>
                </div>
            </div>
            <div class="text-right">
                <mercur-button class="btn" v-if="isEdit" @click="deleteConfirmationDialogActive = true">Remove</mercur-button>
                <mercur-button type="submit" :disabled="$v.$invalid || isLoading" class="btn btn-raised btn-yellow">Submit</mercur-button>
            </div>
        </form>
        <mercur-dialog :is-open.sync="deleteConfirmationDialogActive">
            <h2 class="font-weight-normal">Are you sure you want to remove this category?</h2>
            <p>It is not possible to recover after deleting. Only if no products are using this category the category will be removed.</p>
            <template slot="footer">
                <mercur-button @click="deleteConfirmationDialogActive = false" class="btn">Cancel</mercur-button>
                <mercur-button class="btn btn-primary" @click="deleteCategory">Delete</mercur-button>
            </template>
        </mercur-dialog>
    </mercur-card>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'ProductContentCategoryDetail',
    props: {
        categoryId: {
            default: null,
        },
    },
    data () {
        return {
            deleteConfirmationDialogActive: false,
            form: {
                categoryName: null,
            },
        }
    },
    computed: {
        breadcrumbElement () {
            return {
                name: '...',
            }
        },
        isEdit () {
            return this.categoryId !== null
        },
        isLoading () {
            return this.isEdit && this.category === null
        },
        category () {
            return this.$store.getters['productContent/getCategoryById'](this.categoryId)
        },
    },
    validations: {
        form: {
            categoryName: {
                required,
            },
        },
    },
    methods: {
        deleteCategory () {
            this.$store.dispatch('productContent/removeProductContentCategory', this.categoryId).then(() => {
                this.deleteConfirmationDialogActive = false
                this.$router.push({
                    name: 'ProductContentCategoriesView',
                })
            })
        },
        submit () {
            this.$store.dispatch('productContent/saveProductContentCategory', this.form).then(() => {
                this.$router.push({
                    name: 'ProductContentCategoriesView',
                })
            })
        },
    },
    created () {
        this.$watch('category', () => {
            if (!this.category) {
                return
            }
            this.$set(this, 'form', JSON.parse(JSON.stringify(this.category)))
        }, {
            immediate: true,
        })
    },
}
</script>
